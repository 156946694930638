import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { AngularFirestore } from '@angular/fire/firestore';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-kpa-web',
  templateUrl: './kpa-web.component.html',
  styleUrls: ['./kpa-web.component.css']
})
export class KpaWebComponent implements OnInit {

  today = new Date();
  maxDate = new Date();
  dailyDate = new Date();
  street : string;
  streetId : string;
  doneFlag : any;
  KPAImage ={
    imageSebelum: '',
    imageSemasa: '',
    imageSelepas: '',
    timeSebelum:'',
    timeSemasa:'',
    timeSelepas:'',
  };
  scheduleInfo= '';
  streetInfo: any;
  constructor(private route: ActivatedRoute,
              private firestore: AngularFirestore,
               private angularFireAuth: AngularFireAuth,
               private spinner: NgxSpinnerService

    ) {


     }

  ngOnInit() {
    this.streetId = this.route.snapshot.paramMap.get('id');
    this.datePicked();
  }

  datePicked(){
    const year = this.dailyDate.getFullYear();
    const day = ('0' + this.dailyDate.getDate()).slice(-2);
    const month = ('0' + (this.dailyDate.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.spinner.show();
    this.angularFireAuth.auth.signInWithEmailAndPassword("guest@guest.com", "123456789abcdefghi").then(e=>{
      this.firestore.collection('streets').doc(this.streetId).get().forEach(a=>{
        this.street = a.data().streetName;
        const day = this.dailyDate.getDay();
        this.streetInfo = {
          mon: this.getCheck(a.data(), 1),
          tue: this.getCheck(a.data(), 2),
          wed: this.getCheck(a.data(), 3),
          thu: this.getCheck(a.data(), 4),
          fri: this.getCheck(a.data(), 5),
          sat: this.getCheck(a.data(), 6),
          sun: this.getCheck(a.data(), 7)
        }
        this.scheduleInfo = '';
        this.checkDay(day,a.data());

      })
      this.firestore.collection('reports').doc(dateString).collection('reports',ref=>ref.where('choosedStreetID','==',this.streetId)).get().forEach(doc=>{
        this.spinner.hide();
        if(doc.size >= 3){
          this.doneFlag= true;

        } else{
          this.doneFlag= false
          this.KPAImage ={
            imageSebelum: '',
            imageSemasa: '',
            imageSelepas: '',
            timeSebelum:'',
            timeSemasa:'',
            timeSelepas:'',
          };
          }
        doc.forEach(data=>{
          if(data.data().type === 'sebelum'){
            this.KPAImage.imageSebelum =data.data().image;
            this.KPAImage.timeSebelum =data.data().date.toDate();
          }
          if(data.data().type === 'selepas'){
            this.KPAImage.imageSelepas =data.data().image;
            this.KPAImage.timeSelepas =data.data().date.toDate();
          }
          if(data.data().type === 'semasa'){
            this.KPAImage.imageSemasa =data.data().image;
            this.KPAImage.timeSemasa =data.data().date.toDate();
          }
        })
      })
    })
  }

  getCheck(street, day) {
    if (street.dayAvailables.find(i => i === day)) {
      return true;
    } else {
      return false;
    }
  }

  checkDay(today,data){
    console.log(today);
    switch(today){
      case 0 :
      if(this.streetInfo.sun){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 1 :
      if(this.streetInfo.mon){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 2 :
      if(this.streetInfo.tue){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 3 :
      if(this.streetInfo.wed){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 4 :
      if(this.streetInfo.thu){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 5 :
      if(this.streetInfo.fri){
        this.scheduleInfo = 'sedang dijalankan'
      }
      else{
        this.scheduleInfo += this.getCheck(data,1)? '\nIsnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;
      case 6 :
      if(this.streetInfo.sat){
        this.scheduleInfo = 'sedang dijalankan'
      }else{
        this.scheduleInfo += this.getCheck(data,1)? 'Isnin,': '';
        this.scheduleInfo += this.getCheck(data,2)? 'Selesa,': '';
        this.scheduleInfo += this.getCheck(data,3)? 'Rabu,': '';
        this.scheduleInfo += this.getCheck(data,4)? 'Khamis,': '';
        this.scheduleInfo += this.getCheck(data,5)? 'Jummat,': '';
        this.scheduleInfo += this.getCheck(data,6)? 'Sabtu,': '';
        this.scheduleInfo += this.getCheck(data,7)? 'Ahad,': '';
      }
      break;

    }
  }

}

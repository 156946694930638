import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-pps-web',
  templateUrl: './pps-web.component.html',
  styleUrls: ['./pps-web.component.css']
})
export class PpsWebComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

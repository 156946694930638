import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmationDialogComponent } from '../../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-pdf-range-picker',
  templateUrl: './pdf-range-picker.component.html',
  styleUrls: ['./pdf-range-picker.component.css']
})
export class PdfRangePickerComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  DateFrom: Date;
  DateTo: Date;
  firstDay: any;
  lastDay: any;

  constructor(
    private dialogRef: MatDialogRef<PdfRangePickerComponent>,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }

  confirm(){
    const date={
      from: this.DateFrom,
      to: this.DateTo
    };
    this.firstDay = new Date(this.DateFrom);
    this.lastDay = new Date(this.DateTo);

    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Export the data of this date: ' + this.firstDay.toLocaleDateString() + ' to ' + this.lastDay.toLocaleDateString(),
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {

        this.dialogRef.close(date);
      }
    })
  }


}

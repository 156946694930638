import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Component, OnInit, Inject, ChangeDetectorRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { AddRouteComponent } from '../../monitor/add-route/add-route.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-manage-lorry',
  templateUrl: './manage-lorry.component.html',
  styleUrls: ['./manage-lorry.component.css']
})
export class ManageLorryComponent implements OnInit, AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<AddRouteComponent>,
    @Inject(MAT_DIALOG_DATA) data,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    private dialog: MatDialog,
    private cd: ChangeDetectorRef,
    private toast: ToastrService
  ) { }

  lorries = [];
  ngOnInit(): void {

  }
  ngAfterViewInit() {
    this.setup();
  }
  async setup() {
    this.firestore.collection('lorries', ref => ref.orderBy('name','asc')).get().forEach(l => {
      l.forEach(ldoc => {
        this.lorries.push(ldoc.data());
      });
    });
  }

  cancel() {
    this.dialogRef.close(false);
  }


  addLorry(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Enter the new lorry type',
      lorry: true
    }

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if(result){
        const data = {
          name: result.lorry,
          totalNum: 0
        };
        const duplicate = this.lorries.find(l => l.name === data.name);
        if(duplicate){
          this.toast.error('Insert new record failed', 'Duplicate name');
        } else{
          this.firestore.collection('lorries').add(data).then(r => {
            this.lorries.push(data);
          });
        }
      }
    });
  }

}

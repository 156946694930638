import { MediaMatcher } from '@angular/cdk/layout';
import { ChangeDetectorRef, Component, OnInit, ViewChild } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from '@angular/fire/storage';
import { MatPaginator, MatDialog, PageEvent, Sort, MatDialogConfig } from '@angular/material';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { ConfirmationDialogComponent } from 'src/app/shared/confirmation-dialog/confirmation-dialog.component';
import { ManageLorryComponent } from '../device-management/manage-lorry/manage-lorry.component';
import { DateService } from '../Services/date.service';
import { FirestoreServiceService } from '../Services/firestore-service.service';

@Component({
  selector: 'app-aduan',
  templateUrl: './aduan.component.html',
  styleUrls: ['./aduan.component.css']
})
export class AduanComponent implements OnInit {

  devicelist = [];
  sorteddevicelist: any;
  length = 0;
  pageSize = 10;
  pageSizeOptions: number[] = [10, 25, 50];
  pageIndex = 0;
  offset = this.pageSize * this.pageIndex;
  searchbox = '';
  searchValue = '';
  sortedu = {
    active: '',
    direction: ''
  };
  lorries = [];
  role:any;

  @ViewChild('topPaginator', { read: MatPaginator, static: true }) topPaginator: MatPaginator;
  @ViewChild('bottomPaginator', { read: MatPaginator, static: true }) bottomPaginator: MatPaginator;

  constructor(
    private fireSrv: FirestoreServiceService,
    changeDetectorRef: ChangeDetectorRef,
    private storageRef: AngularFireStorage,
    media: MediaMatcher,
    private firestore: AngularFirestore,
    router: Router,
    private dialog: MatDialog,
    private dateSrv: DateService,
    private toaster: ToastrService,
    private spinner: NgxSpinnerService
  ) {
    this.role = localStorage.getItem('Role');
  }

  ngOnInit() {
    this.spinner.show();
    const geocoder = new google.maps.Geocoder();
    this.SetupTable(geocoder);

  }

  async SetupTable(geocoder: google.maps.Geocoder,) {
    await this.firestore.collection('reports').get().forEach(r=>{
      console.log(r.size)
      this.spinner.hide();
      r.forEach(sub=>{
        console.log(sub.id);
        this.firestore.collection('reports').doc(sub.id)
        .collection('reports', ref=>ref.where('mode','==',this.role.toLowerCase())).get().forEach(re=>{
          re.forEach(subb=>{
            console.log(subb.data());
          });
        });
      });
    });

  }

  SetupLorry(){
    this.firestore.collection('lorries', ref => ref.orderBy('name','asc')).get().forEach( e => {
      console.log(e.size);
      e.forEach(lorry => {
        const data = {
          ...lorry.data(),
          id: lorry.id
        }
        this.lorries.push(data);
      })
    });
  }

  paginator(pageEvent: PageEvent) {
    this.pageSize = pageEvent.pageSize;
    this.pageIndex = pageEvent.pageIndex;
    this.offset = this.pageSize * this.pageIndex;
    if (this.topPaginator.pageIndex < this.pageIndex) {
      this.topPaginator.nextPage();
    } else if (this.topPaginator.pageIndex > this.pageIndex) {
      this.topPaginator.previousPage();
    }
    if (this.bottomPaginator.pageIndex < this.pageIndex) {
      this.bottomPaginator.nextPage();
    } else if (this.bottomPaginator.pageIndex > this.pageIndex) {
      this.bottomPaginator.previousPage();
    }
    this.sorteddevicelist = this.devicelist.slice();
    this.sortDevice();
    this.limitDevice();
  }

  limitDevice() {
    this.sorteddevicelist = this.sorteddevicelist.slice(this.offset, (this.offset + this.pageSize));
  }

  sortData(sort: Sort) {
    this.sortedu = sort;
    this.sorteddevicelist = this.devicelist.slice();
    if (this.searchValue) {
      this.DS();
    }
    if (!sort.active || sort.direction === '' && !this.searchValue) {
      this.sorteddevicelist = this.devicelist.slice();
      this.limitDevice();
      return;
    }

    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'latitude': return this.compare(a.latitude, b.latitude, isAsc);
        case 'longitude': return this.compare(a.longitude, b.longitude, isAsc);
        case 'location': return this.compare(a.location, b.location, isAsc);
        default: return 0;
      }
    });
    this.limitDevice();
  }

  sortDevice() {
    if (!this.sortedu.active || this.sortedu.direction === '') {
      return;
    }
    this.sorteddevicelist = this.sorteddevicelist.sort((a, b) => {
      const isAsc = this.sortedu.direction === 'asc';
      switch (this.sortedu.active) {
        case 'id': return this.compare(a.id, b.id, isAsc);
        case 'lorry': return this.compare(a.LorryType, b.LorryType, isAsc);
        case 'latitude': return this.compare(a.latitude, b.latitude, isAsc);
        case 'longitude': return this.compare(a.longitude, b.longitude, isAsc);
        case 'location': return this.compare(a.location, b.location, isAsc);
        default: return 0;
      }
    });
  }

  compare(a: number | string, b: number | string, isAsc: boolean) {

    return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
  }

  dynamicSearch() {
    this.searchValue = this.searchbox;
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.LorryType).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.latitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.longitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.location).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
    this.limitDevice();
  }

  DS() {
    this.sorteddevicelist = this.devicelist.filter(u =>
      String(u.id).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.LorryType).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.latitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.longitude).toLowerCase().includes(this.searchValue.toLowerCase()) ||
      String(u.location).toLowerCase().includes(this.searchValue.toLowerCase()));
    this.length = this.sorteddevicelist.length;
    this.sortDevice();
  }

  assign(lorry, id) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    dialogConfig.data = {
      message: 'Assign this device ' + id + ' to ' + lorry.name
    };
    lorry.totalNum++;
    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(result => {
      if (result) {
        this.firestore.collection('devices').doc(id)
          .update({ LorryType: lorry.name, No: lorry.totalNum }).then(s => {
          const r = this.sorteddevicelist.find(d => d.id === id);
          r.LorryType = lorry.name;
          this.firestore.collection('lorries').doc(lorry.id).update({totalNum: lorry.totalNum});

        });
      }
    });
  }

  openManageLorryTab(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '400px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;

    this.dialog.open(ManageLorryComponent, dialogConfig).afterClosed().subscribe(result => {
      this.lorries = [];
      this.firestore.collection('lorries', ref => ref.orderBy('name','asc')).get().forEach( e => {
        e.forEach(lorry => {
          const data = {
            ...lorry.data(),
            id: lorry.id
          }
          this.lorries.push(data);
        })
      });
    });
  }


}


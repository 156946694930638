import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DateService {
  private date = new BehaviorSubject<Date>(new Date());
  currentDate = this.date.asObservable();
  constructor() { }

  SetDate(date: Date) {
    this.date.next(date);
  }

}

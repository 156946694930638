import { MapRouteComponent } from './../../map-route/map-route.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import { SpeedGraphComponent } from '../../speed-graph/speed-graph.component';

@Component({
  selector: 'app-trip-info',
  templateUrl: './trip-info.component.html',
  styleUrls: ['./trip-info.component.css']
})
export class TripInfoComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<TripInfoComponent>,
    private firestore: AngularFirestore,
    private dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.trip = data.trip;
    this.information = data;
    if(!data.date_complete){
      this.flag = false;
    }
  }

  information: any;
  trip: any;
  flag=true;

  aduan = [];
  operasi = [];

  ngOnInit() {
    this.setup();
  }

  async setup() {
    const date = new Date(this.information.date_start.toDate());
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    console.log(this.information.trip);
    this.firestore.collection('reports').doc(dateString)
                  .collection('reports', ref => ref.where('deviceId', '==', this.information.deviceId)
                  .where('trip', '==' , this.information.trip.toString()).where('mode', '==', 'pps')).get().forEach(e => {
                    e.forEach(document => {
                      if (document.data().type === 'operasi') {
                        this.operasi.push(document.data());
                      } else {
                        this.aduan.push(document.data());
                      }
                    });
                  });
  }

  close() {
    this.dialogRef.close(false);
  }

  viewRoute(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '80%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.information;

    this.dialog.open(MapRouteComponent, dialogConfig).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });
  }

  viewGraph(){
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '1000px';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = this.information;

    this.dialog.open(SpeedGraphComponent, dialogConfig).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });
  }
}

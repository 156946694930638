import { TripInfoComponent } from './trip-info/trip-info.component';
import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogConfig, MatDialog } from '@angular/material';
import { SpeedGraphComponent } from '../speed-graph/speed-graph.component';
import { AngularFirestore } from '@angular/fire/firestore';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-device-info-modal',
  templateUrl: './device-info-modal.component.html',
  styleUrls: ['./device-info-modal.component.css']
})
export class DeviceInfoModalComponent implements OnInit {

  history: any;
  information: any;
  dates: any = [];
  duration: any = 0;
  durationString: any = 0;
  deviceId: any;
  minDate: Date;
  maxDate: Date;
  dailyDate: Date;
  trips = [];
  flag = true;
  constructor(
    private dialogRef: MatDialogRef<DeviceInfoModalComponent>,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) data,

  ) {
    this.deviceId = data.id;
    this.history = data.data;
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();
    this.dailyDate = new Date();
    this.setup(this.dailyDate);
  }

  async setup(date) {
    this.trips = [];
    date.setHours(0, 0, 0);
    const nextDate = new Date(date);
    nextDate.setDate(nextDate.getDate() + 1);
    this.firestore.collection('devices').doc(this.deviceId).collection('trips',
      ref => ref.where('date_start', '>=', date).
        where('date_start', '<', nextDate).
        orderBy('date_start', 'asc')).get().forEach((e) => {
          this.spinner.hide();
          e.forEach(element => {
            this.trips.push({
              ...element.data(),
              id: element.id,
            });
          })
        });
  }

  ngOnInit() {
    this.spinner.show();
  }

  close() {
    this.dialogRef.close(false);
  }

  viewTripInfo(device) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = '60%';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = device;

    this.dialog.open(TripInfoComponent, dialogConfig).afterClosed().subscribe(result => {
      if (!result) {
        return;
      }
    });

  }

  insert(str, index, value) {
    return str.substr(0, index) + value + str.substr(index);
  }

  millisToMinutesAndSeconds(millis) {
    const minutes = Math.floor(millis / 60000);
    const seconds = ((millis % 60000) / 1000);
    return minutes + ' min ' + (seconds < 10 ? '0' : '') + seconds.toFixed(0) + ' sec';
  }

  datePicked() {
    const d = new Date(this.dailyDate);
    this.spinner.show();
    this.setup(d);

  }

}

import { Component, OnInit, Inject, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from '@angular/material';
import { AngularFirestore } from '@angular/fire/firestore';
import * as firebase from 'firebase';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-map-route',
  templateUrl: './map-route.component.html',
  styleUrls: ['./map-route.component.css']
})
export class MapRouteComponent implements OnInit, AfterViewInit {

  constructor(
    private dialogRef: MatDialogRef<MapRouteComponent>,
    private dialog: MatDialog,
    private firestore: AngularFirestore,
    private spinner: NgxSpinnerService,
    @Inject(MAT_DIALOG_DATA) data,

  ) {
    this.device = data;
    this.role = localStorage.getItem('Role');
  }

  device: any;
  map: google.maps.Map;
  marker: google.maps.Marker;
  mapOptions: google.maps.MapOptions;
  coordinates: any;
  lat = 4.2105;
  lng = 101.9758;
  role: any;
  imageServices: any;
  trackingServices: any;

  @ViewChild('mapContainer', { static: false }) gmap: ElementRef;
  lorrylist = [];
  markerlist = [];
  positionlist = [];
  linelist = [];
  locatelist = [];
  savinglist = [];
  trackingPath: any;
  imagelist = [];

  ngOnInit() {
    this.spinner.show();
  }

  ngAfterViewInit(): void {
    this.coordinates = new google.maps.LatLng(this.lat, this.lng);
    this.mapOptions = {
      center: this.coordinates,
      zoom: 15
    };
    this.map = new google.maps.Map(this.gmap.nativeElement, this.mapOptions);
    this.setup();
    this.GetImage();
  }
  async setup(){
    var url = '';
    if (this.role === 'PPS') {
      url = 'https://img.icons8.com/dusk/64/000000/garbage-truck.png';
    } else {
      url = 'https://img.icons8.com/plasticine/100/000000/housekeeping.png';
    }
    this.trackingServices = this.firestore.collection('route', ref => ref.where('id', '==', this.device.id)
    .where('trip', '==', this.device.trip).where('deviceId', '==', this.device.deviceId.toString()))
    .get().forEach(route => {
      if (route.size > 0) {
        route.forEach(doc => {
          const locationLength = doc.data().coordinate.length;
          const mark = {
            position: new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
              doc.data().coordinate[locationLength - 1].longitude),
            map: this.map,
            icon: url,
            trip: this.device.trip,
            date: doc.data().date_complete.toDate(),
            id: this.device.deviceId
          };
          const marker = new google.maps.Marker({
            ...mark
          });
          this.lorrylist.push(marker);
          marker.setMap(this.map);
          this.markerlist.push(marker);
          const infoWindow = new google.maps.InfoWindow({
            content: '<span>Device ID: ' + mark.id + '</span><br>' +
              '<span>Trip: ' + mark.trip + '</span><br>'
          });
          marker.addListener('click', () => {
            this.map.setZoom(18);
            this.map.setCenter(marker.getPosition());
            infoWindow.open(marker.getMap(), marker);
          });
          this.map.panTo(new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
          doc.data().coordinate[locationLength - 1].longitude));

          if (locationLength > 1) {
            const list = [];
            if (this.trackingPath) {
              this.trackingPath.setMap(null);
            }
            for (let i = 0; i < locationLength; i++) {
              list.push(new google.maps.LatLng(doc.data().coordinate[i].latitude,
                doc.data().coordinate[i].longitude));
            }
            this.trackingPath = new google.maps.Polyline({
              path: list,
              strokeColor: 'Green',
              strokeOpacity: 1.0,
              strokeWeight: 2,
            });
            this.trackingPath.setMap(this.map);
            this.linelist.push(this.trackingPath);
            this.map.panTo(new google.maps.LatLng(doc.data().coordinate[locationLength - 1].latitude,
            doc.data().coordinate[locationLength - 1].longitude));
            this.spinner.hide();
          }
        });
      } else {
        this.firestore.collection('devices').doc(this.device.deviceId).collection('trips')
          .doc(this.device.id).collection('locations').snapshotChanges().subscribe((l) => {
            console.log(l);
            l.forEach((location, index) => {
              const same = this.locatelist.find(l => l.payload.doc.id === location.payload.doc.id);
              if (!same) {
                this.locatelist.push(location);
                if (index === 0) {
                  const mark = {
                    position: new google.maps.LatLng(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude),
                    map: this.map,
                    icon: url,
                    trip: this.device.trip,
                    date: location.payload.doc.data().last_sync.toDate(),
                    id: this.device.deviceId
                  };
                  const marker = new google.maps.Marker({
                    ...mark
                  });
                  this.lorrylist.push(marker);
                  marker.setMap(this.map);
                  this.markerlist.push(marker);

                  const infoWindow = new google.maps.InfoWindow({
                    content: '<span>Device ID: ' + mark.id + '</span><br>' +
                      '<span>Trip: ' + mark.trip + '</span><br>'
                  });
                  marker.addListener('click', () => {
                    this.map.setZoom(18);
                    this.map.setCenter(marker.getPosition());
                    infoWindow.open(marker.getMap(), marker);
                  });
                } else {
                  const r = this.lorrylist.find(m => m.id === this.device.deviceId && m.trip === this.device.trip);
                  if (r) {
                    r.date = location.payload.doc.data().last_sync.toDate();
                    r.setPosition(
                      new google.maps.LatLng(location.payload.doc.data().location.latitude, location.payload.doc.data().location.longitude)
                    );
                    this.map.panTo(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                      location.payload.doc.data().location.longitude));
                    if (this.positionlist[this.positionlist.length - 1] !==
                      new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude)) {
                      this.positionlist.push(new google.maps.LatLng(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude));
                      this.savinglist.push(new firebase.firestore.GeoPoint(location.payload.doc.data().location.latitude,
                        location.payload.doc.data().location.longitude))
                    }

                    if (index === l.length - 1) {
                      if (this.device.date_complete) {
                        const data = {
                          coordinate: this.savinglist,
                          ...this.device
                        }
                        this.firestore.collection('route').add(data);
                      }
                      if (this.positionlist.length > 1) {
                        const list = [];
                        if (this.trackingPath) {
                          this.trackingPath.setMap(null);
                        }
                        for (let i = 0; i < this.positionlist.length; i++) {
                          list.push(this.positionlist[i]);
                        }
                        this.trackingPath = new google.maps.Polyline({
                          path: list,
                          strokeColor: 'Green',
                          strokeOpacity: 1.0,
                          strokeWeight: 2,
                        });
                        this.trackingPath.setMap(this.map);
                        this.linelist.push(this.trackingPath);
                        this.spinner.hide();

                      }
                    }
                  }
                }
                index++;
              }
            })
          })
      }
    });

  }

  GetImage() {
    const date = new Date(this.device.date_start.toDate());
    const year = date.getFullYear();
    const day = ('0' + date.getDate()).slice(-2);
    const month = ('0' + (date.getMonth() + 1)).slice(-2);
    const dateString = year + month + day;
    this.imageServices =  this.firestore.collection('reports').doc(dateString).
    collection('reports', ref => ref.where('deviceId', '==', this.device.deviceId).
    where('trip', '==' , this.device.trip.toString()).where('mode', '==', this.role.toLowerCase())).snapshotChanges().subscribe(result => {
        result.forEach(e => {
          let info = {
            position: new google.maps.LatLng(e.payload.doc.data().location.latitude,
              e.payload.doc.data().location.longitude),
            latitude: e.payload.doc.data().location.latitude,
            longitude: e.payload.doc.data().location.longitude,
            map: this.map,
            id: e.payload.doc.data().deviceId,
            date: e.payload.doc.data().date,
            imageurl: e.payload.doc.data().image,
            type: e.payload.doc.data().type,
          };
          this.imagelist.push(info);
        })
        console.log(this.imagelist);
        this.imagelist.forEach(e => {
          var marker;
          if (e.type === 'operasi') {
             marker = new google.maps.Marker({
              ...e,
              icon : 'http://maps.google.com/mapfiles/ms/icons/blue-dot.png'

            });
          }else{
            marker = new google.maps.Marker({
              ...e,
              icon : 'http://maps.google.com/mapfiles/ms/icons/red-dot.png'

            });
          }
          marker.setMap(this.map);
          this.map.setCenter(marker.getPosition());
          var infoWindow;
          const d = new Date(e.date.toDate());
          const hrs = d.getHours();
          const minute = d.getMinutes();
          const sec = d.getSeconds();
          var display;
          console.log(e.type);
          if (hrs > 12) {
            display = hrs + ':' + minute + ':' + sec + 'pm';
          } else {
            display = hrs + ':' + minute + ':' + sec + 'am';
          }
          if (e.type === 'operasi') {
            infoWindow = new google.maps.InfoWindow({
              content:
                '<span>Waktu Operasi:' + display + ' </span><br><br>' +
                '<div class="operasiFrame" style="width: 250px;height: 250px;border: 3px solid green;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
            });
          } else {
            infoWindow = new google.maps.InfoWindow({
              content:
                '<span>Waktu Aduan Halangan:' + display + ' </span><br><br>' +
                '<div class="aduanFrame" style="width: 250px;height: 250px;border: 3px solid red;background: #eee;margin: auto; padding: 15px 10px;"><img src=' + e.imageurl + ' width="100%" height="100%"></img></div>'
            });
          }

          marker.addListener('click', () => {
            this.map.setZoom(18);
            this.map.setCenter(marker.getPosition());
            infoWindow.open(marker.getMap(), marker);
          });
        });
      })
  }
  close() {
    this.dialogRef.close(false);
  }
}

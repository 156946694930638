import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-trip-select',
  templateUrl: './trip-select.component.html',
  styleUrls: ['./trip-select.component.css']
})
export class TripSelectComponent implements OnInit {

  trips: any;
  deviceId: any;
  date: any
  constructor(
    private dialogRef: MatDialogRef<TripSelectComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.date = new Date(data.date);
    this.deviceId = data.deviceId;
    this.trips = data.trips;
  }

  ngOnInit() { }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(trip) {
    this.dialogRef.close(trip);
  }

}

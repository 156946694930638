import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireAuth } from '@angular/fire/auth';

@Injectable({
  providedIn: 'root'
})
export class FirestoreServiceService {

  constructor(private firestore: AngularFirestore, private fireauth: AngularFireAuth) { }


  SavaStreet(data) {
    return this.firestore.collection('Streets').add(data);
  }

  SavaRoute(data) {
    return this.firestore.collection('route').add(data);
  }

}

import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../Services/auth.service';

@Component({
  selector: 'app-menu-chosen-page',
  templateUrl: './menu-chosen-page.component.html',
  styleUrls: ['./menu-chosen-page.component.css']
})
export class MenuChosenPageComponent implements OnInit {

  constructor(
    private router: Router,
    private authSrv: AuthService,

  ) { }

  ngOnInit() {
  }

  PPS(){
    localStorage.setItem('Role', 'PPS');
    this.router.navigate(['home/monitor']);
  }

  KPA(){
    localStorage.setItem('Role', 'KPA');
    this.router.navigate(['home/monitor']);

  }

}

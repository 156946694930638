import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog, MatDialogConfig } from '@angular/material';
import { ConfirmationDialogComponent } from '../confirmation-dialog/confirmation-dialog.component';

@Component({
  selector: 'app-monitor-date-picker',
  templateUrl: './monitor-date-picker.component.html',
  styleUrls: ['./monitor-date-picker.component.css']
})
export class MonitorDatePickerComponent implements OnInit {

  minDate: Date;
  maxDate: Date;
  dailyDate : Date;
  WeeklyDate : Date;
  MonthDate : Date;
  chooseDate: Date;
  firstDay: any;
  lastDay: any;

  constructor(
    private dialogRef: MatDialogRef<MonitorDatePickerComponent>,
    @Inject(MAT_DIALOG_DATA) data, private dialog: MatDialog) {
    const currentYear = new Date().getFullYear();
    this.minDate = new Date(currentYear - 20, 0, 1);
    this.maxDate = new Date();

  }

  ngOnInit() {
  }

  close() {
    this.dialogRef.close();
  }


  datePicked() {
    this.chooseDate = this.dailyDate;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.height = 'auto';
    dialogConfig.width = 'auto';
    const position = {
      top: '5%',
    };
    dialogConfig.position = position;
    dialogConfig.disableClose = true;
    dialogConfig.autoFocus = true;
    dialogConfig.data = {
      message: 'Select this date ' + this.chooseDate.toLocaleDateString() + '?',
    };

    this.dialog.open(ConfirmationDialogComponent, dialogConfig).afterClosed().subscribe(r => {
      if (r) {
        this.dialogRef.close(this.chooseDate);
      }
    })
  }

}

import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
  selector: 'app-route-select',
  templateUrl: './route-select.component.html',
  styleUrls: ['./route-select.component.css']
})
export class RouteSelectComponent implements OnInit {
  routes: any;
  deviceId: any;
  date: any
  constructor(
    private dialogRef: MatDialogRef<RouteSelectComponent>,
    @Inject(MAT_DIALOG_DATA) data
  ) {
    this.date = new Date(data.date);
    this.deviceId = data.deviceId;
    this.routes = data.routes;
  }

  ngOnInit() { }

  cancel() {
    this.dialogRef.close(false);
  }

  confirm(r) {
    this.dialogRef.close(r);
  }

}
